import { DialogActions } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  insertCustomAd,
  updateCustomAd,
} from "../../Store/advertisement/action";
import { connect, useSelector } from "react-redux";
import { baseURL } from "../../util/ServerPath";

const CustomAdDialog = (props) => {
  const dialogData = JSON.parse(localStorage.getItem("customAdData"));
  const history = useHistory();

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState(0);
  const [publisherName, setPublisherName] = useState(null);
  const [publisherLogo, setPublisherLogo] = useState([]);
  const [publisherLogoPath, setPublisherLogoPath] = useState("");
  const [productImage, setProductImage] = useState([]);
  const [productImagePath, setProductImagePath] = useState("");
  const [productTag, setProductTag] = useState(null);
  const [productUrl, setProductUrl] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [video, setVideo] = useState([]);
  const [videoPath, setVideoPath] = useState("");
  const [error, setError] = useState({
    title: "",
    url: "",
    video: "",
    productImage: "",
    productUrl: "",
    productTag: "",
  });

  //set data in dialog
  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setTitle(dialogData.title);
      setDescription(dialogData.description);
      setUrl(dialogData.url);
      setType(dialogData.type);
      setPublisherLogoPath(baseURL + dialogData.publisherLogo);
      setPublisherName(dialogData.publisherName);
      setVideoPath(baseURL + dialogData?.video);
      setProductImagePath(baseURL + dialogData?.productImage);
      setProductUrl(dialogData.productUrl);
      setProductTag(dialogData.productTag);
    }
  }, []);

  useEffect(
    () => () => {
      setError({
        title: "",
        url: "",
        image: "",
        video: "",
        productImage: "",
        productUrl: "",
        productTag: "",
      });
      setMongoId("");
      setTitle("");
      setUrl("");
      setDescription("");
      setVideo([]);
      setPublisherLogo([]);
      setProductImage([]);
      setProductTag("");
      setProductUrl("");
    },
    []
  );

  //Handle Publisher Logo Function
  const handlePublisherLogo = (event) => {
    if (event.target.files[0]) {
      setPublisherLogo(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPublisherLogoPath(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // handle product image loading
  const handleProductImage = (event) => {
    if (event.target.files[0]) {
      setProductImage(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setProductImagePath(reader.result);
        setError({ productImage: "" });
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // Upload Video
  const handleVideo = (event) => {
    if (event.target.files[0]) {
      setVideo(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setVideoPath(reader.result);
        setError({ video: "" });
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  //close dialog
  const handleClose = () => {
    localStorage.removeItem("customAdData");
    history.push("/admin/advertisement/customAd");
  };

  //Handle Submit Function for insert and update
  const isLink = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^(ftp|http|https):\/\/[^ "]+$/.test(val);
    return validNumber;
  };

  const handleSubmit = () => {
    const validLinkUrl = isLink(url);
    const validLinkProductUrl = isLink(productUrl);
    if (
      !title ||
      !url ||
      !video ||
      !validLinkUrl ||
      !productImage ||
      !productTag ||
      !productUrl ||
      !validLinkProductUrl
    ) {
      const error = {};
      if (!title) error.title = "TItle is Required !";
      if (!url) {
        error.url = "Url is Required !";
      } else if (!validLinkUrl) {
        error.url = "Url Invalid !";
      }
      if (video.length === 0) error.video = "Video is Required!";
      if (productImage.length === 0)
        error.productImage = "Product Image is Required!";
      if (!productTag) error.productTag = "Product Tag is Required!";
      if (!productUrl) {
        error.productUrl = "Product URL is Required!";
      } else if (!validLinkProductUrl) {
        error.productUrl = "Product URL Invalid!";
      }

      return setError({ ...error });
    }

    const formData = new FormData();

    formData.append("title", title);
    formData.append("url", url);
    formData.append("description", description);
    formData.append("video", video);
    formData.append("publisherLogo", publisherLogo);
    formData.append("publisherName", publisherName);
    formData.append("type", type);
    formData.append("productImage", productImage);
    formData.append("productTag", productTag);
    formData.append("productUrl", productUrl);

    //

    if (mongoId) {
      props.updateCustomAd(mongoId, formData);
    } else {
      props.insertCustomAd(formData);
    }

    history.push("/admin/advertisement/customAd");
  };

  return (
    <div id="content" class="main-content">
      <div class="layout-px-spacing mt-4">
        <div className="row py-2">
          <div class="col-xl-6 col-md-6 col-sm-12 col-12">
            <h4>Custom Ad Dialog </h4>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12 col-12 ">
            <div class="breadcrumb-four float-right">
              <ul class="breadcrumb">
                <li>
                  <Link to="/admin/dashboard">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/advertisement/customAd"> Custom Ad </Link>
                </li>
                <li class="active">
                  <a href="javscript:void(0);"> Custom Ad Dialog</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body card-overflow">
                <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="mb-2 text-gray">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          required=""
                          placeholder="20"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                title: "Title is Required!",
                              });
                            } else {
                              return setError({
                                ...error,
                                title: "",
                              });
                            }
                          }}
                        />
                        {error.title && (
                          <span style={{ color: "red" }}>{error.title}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="mb-2 text-gray">Description</label>
                        <textarea
                          type="text"
                          className="form-control"
                          required=""
                          placeholder="20"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                description: "Description is Required!",
                              });
                            } else {
                              return setError({
                                ...error,
                                description: "",
                              });
                            }
                          }}
                        />
                        {error.description && (
                          <span style={{ color: "red" }}>
                            {error.description}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 my-2">
                      <label class="float-left styleForTitle">
                        Publisher Name
                      </label>
                      <input
                        type="text"
                        placeholder="Publisher Name"
                        class="form-control form-control-line"
                        Required
                        value={publisherName}
                        onChange={(e) => {
                          setPublisherName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-6 my-2">
                      <label class="float-left styleForTitle">
                        Publisher Logo
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        accept="image/png, image/jpeg"
                        Required=""
                        onChange={handlePublisherLogo}
                      />

                      {publisherLogoPath && (
                        <>
                          <img
                            height="100px"
                            width="100px"
                            alt="app"
                            src={publisherLogoPath}
                            draggable="false"
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 50%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              float: "left",
                            }}
                            className="mb-3"
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 my-2">
                      <label class="float-left styleForTitle">URL</label>
                      <input
                        type="text"
                        placeholder="URL"
                        class="form-control form-control-line"
                        Required
                        value={url}
                        onChange={(e) => {
                          setUrl(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              url: "Url is Required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              url: "",
                            });
                          }
                        }}
                      />
                      {error.url && (
                        <span style={{ color: "red" }}>{error.url}</span>
                      )}
                    </div>

                    <div className="col-md-6 my-2">
                      <label class="float-left styleForTitle">Type</label>
                      <select
                        id="contentType"
                        name="contentType"
                        class="form-control form-control-line"
                        Required
                        value={type}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                      >
                        <option value={0}>
                          Native [ with title and description]
                        </option>
                        <option value={1}>Full screen </option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 my-2">
                      <label class="float-left styleForTitle">Video</label>
                      <input
                        type="file"
                        class="form-control"
                        accept="video/*"
                        Required=""
                        onChange={handleVideo}
                      />
                      {error.video && (
                        <div className="mt-1">
                          {error.video && (
                            <span style={{ color: "red" }}>{error.video}</span>
                          )}
                        </div>
                      )}
                      {videoPath && (
                        <>
                          <video
                            controls
                            height="100px"
                            width="100px"
                            alt="app"
                            src={videoPath}
                            draggable="false"
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 50%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              float: "left",
                            }}
                            className="mb-3"
                          />
                        </>
                      )}
                    </div>

                    <div className="col-md-6 my-2">
                      <label class="float-left styleForTitle">
                        Product Image
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        accept="image/png, image/jpeg"
                        Required=""
                        onChange={handleProductImage}
                      />
                      {error.productImage && (
                        <div className="mt-1">
                          {error.productImage && (
                            <span style={{ color: "red" }}>
                              {error.productImage}
                            </span>
                          )}
                        </div>
                      )}
                      {productImagePath && (
                        <>
                          <img
                            height="100px"
                            width="100px"
                            alt="app"
                            src={productImagePath}
                            draggable="false"
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 50%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              float: "left",
                            }}
                            className="mb-3"
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 my-2">
                      <label class="float-left styleForTitle">
                        Product URL
                      </label>
                      <input
                        type="text"
                        placeholder="Product URL"
                        class="form-control form-control-line"
                        Required
                        value={productUrl}
                        onChange={(e) => {
                          setProductUrl(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              productUrl: "Product Url is Required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              productUrl: "",
                            });
                          }
                        }}
                      />
                      {error.productUrl && (
                        <span style={{ color: "red" }}>{error.productUrl}</span>
                      )}
                    </div>

                    <div className="col-md-6 my-2">
                      <label class="float-left styleForTitle">
                        Product Tag
                      </label>
                      <input
                        type="text"
                        placeholder="Product Tag"
                        class="form-control form-control-line"
                        Required
                        value={productTag}
                        onChange={(e) => {
                          setProductTag(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              productTag: "Product Tag is Required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              productTag: "",
                            });
                          }
                        }}
                      />
                      {error.productTag && (
                        <span style={{ color: "red" }}>{error.productTag}</span>
                      )}
                    </div>
                  </div>

                  <DialogActions>
                    {dialogData ? (
                      <button
                        type="button"
                        class="btn btn-success  px-3"
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-success  px-3"
                        onClick={handleSubmit}
                      >
                        Insert
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn  ml-2 bg-dark-gradient btn-round float-right   icon_margin"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </DialogActions>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { insertCustomAd, updateCustomAd })(
  CustomAdDialog
);
